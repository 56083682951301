import React, { useEffect, useState, useRef } from "react";
import "./basicinfo.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Item } from "react-stately";
import icon from "../icons/open.svg";
import ComboBox from "../ComboBox/ComboBox";
import { useMsal } from "@azure/msal-react";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedResources } from "../../authConfig";
import { addWebsite } from "../../utilities/api";
import { setItem, updateItem } from "../../utilities/storage";

const BasicInfo = () => {
  const navigate = useNavigate();
  const [accountName, setAccountName] = useState("");
  const [userName, setUserName] = useState("");
  const [customAccountName, setCustomAccountName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [industryId, setIndustryId] = useState(0);
  const [projectURL, setProjectURL] = useState("");
  const [projectNameError, setProjectNameError] = useState(false);
  const [projectNameErrorText, setProjectNameErrorText] = useState("");
  const [allAccounts, setAllAccounts] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [companyId, setCompanyId] = useState();
  const [disableIndustryInput, setDisableIndustryInput] = useState(false);
  const [auditType, setAuditType] = useState('website');
  const [isScan, setIsScan] = useState(true);
  const selectMenu = useRef(null);

  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const location = useLocation();
  const { state } = location;
  const testId = state?.testId || null; // exists when modifying a previous test
  const { execute } = useFetchWithMsal({
    scopes: [
      ...protectedResources.getAllCompanies.scopes.read,
      ...protectedResources.addNewTest.scopes.write,
    ],
  });
  const isUpload = window.location.href.includes("uploadTest");

  useEffect(() => {
    execute("GET", protectedResources.getAllCompanies.endpoint, protectedResources.getAllCompanies.scopes).then(
      (response) => {
        if (response) {
          setAllAccounts(response);
        }
      }
    );

    execute("GET", protectedResources.getIndustries.endpoint, protectedResources.getIndustries.scopes).then(
      (response) => {
        if (response) {
          setIndustries(response);
        }
      }
    );
  }, [execute]);

  useEffect(() => {
    if (testId) {
      execute("GET", protectedResources.getBasicInfo.endpoint + testId, protectedResources.getBasicInfo.scopes).then(
        (response) => {
          if (response) {
            const basicInfo = response;

            if (basicInfo.length > 0) {
              setAccountName(basicInfo[0].companyname);
              setUserName(basicInfo[0].username);
              setProjectName(basicInfo[0].projectname);
              setProjectURL(basicInfo[0].projecturl);
              setAccountName(basicInfo[0].industryame);
            }
          }
        }
      );
    }
  }, [execute]);

  const getCompanyId = async () => {
    try {
      const response = await execute("GET", protectedResources.getCompany.endpoint, protectedResources.getCompany.scopes);
      if (response) {
        for (let i = 0; i < response.length; i++) {
          if (accountName && response[i].companyname.toUpperCase() === accountName.toUpperCase()) {
            return response[i].companyid;
          }

          if (customAccountName && response[i].companyname.toUpperCase() === customAccountName.toUpperCase()) {
            return response[i].companyid;
          }
        }

        // create new company if not in the list
        const body = {
          companyname: customAccountName,
          industryid: industryId
        };

        const newCompany = await execute("POST", protectedResources.addCompany.endpoint, protectedResources.addCompany.scopes, body);
        return newCompany[0].companyid;
      }
    } catch (error) {
      console.error("Error: ", error);
      throw error;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const companyId = await getCompanyId();

    if (isUpload) {
      navigate("/uploadTest", {
        state: { companyId, projectName, projectURL }
      });
      return;
    }

    const userId = account.localAccountId;
    if (testId) {
      // test was already created and info is being modified
      const basicInfo = {
        userid: userId,
        username: userName,
        companyid: companyId,
        projectname: projectName,
        projecturl: projectURL,
      };

      const endpointUrl = protectedResources.updateBasicInfo.endpoint.replace(':testid', testId);

      await execute(
        "PUT",
        endpointUrl,
        protectedResources.updateBasicInfo.scopes,
        basicInfo
      ).then((response) => {
        if (response) {
          let res = response;
          if (res?.error) {
            setProjectNameError(true);
            setProjectNameErrorText(res.error);
          } else {
            navigate("/ScoringScreen", {
              state: { testId },
            });
          }
        }
      });
    } else {
      const d = new Date();
      const test = {
        userid: account.localAccountId,
        username: account.name,
        companyid: companyId,
        testversionid: 1,
        testscore: 0,
        date: d,
        projectname: projectName,
        projecturl: projectURL,
      };
      execute("POST", protectedResources.addNewTest.endpoint, protectedResources.addNewTest.scopes, test).then(
        (response) => {
          if (response) {
            let testId = response;

            if (testId[0] && testId[0].testid) {
              let auditType = selectMenu.current.value;
              setItem(testId[0].testid, {scan: isScan, audit_type: auditType});
              if(isScan) {
                addWebsite(projectName, projectURL).then((resp) => {
                  updateItem(testId[0].testid, {website_id: resp.data.public_id });
                  const popetech = {
                    testid: testId[0].testid,
                    audittype: auditType,
                    scan: isScan,
                    websiteid: resp.data.public_id,
                  };
                  execute("POST", protectedResources.addPopeTech.endpoint, protectedResources.addPopeTech.scopes, popetech);

                  navigate(`/addPages?testId=${testId[0].testid}`, {
                    state: { testId: testId[0].testid },
                  });
                }).catch(() => {
                  alert("Website could not be created on Pope Tech. It could be because the website URL is invalid or there in a connectivity issue. Please check and try again.");
                });
              } else {
                navigate("/ScoringScreen", {
                  state: { testId: testId[0].testid },
                });
              }
            } else if (testId.error) {
              setProjectNameError(true);
              setProjectNameErrorText(testId.error);
            } else if (testId.message) {
              window.alert(testId.message)
            }
          }
        }
      );

      
    }
  };

  const AccountNameLabel = () => {
    return (
      <label className="biform" htmlFor="accountName" id="accountNameLabel" aria-describedby="addInfo1">
        Account Name<span className="required_label">*</span>
      </label>
    );
  };

  const IndustryNameLabel = () => {
    return (
      <label className="biform" htmlFor="industryName" id="industryNameLabel">
        Industry Name<span className="required_label">*</span>
      </label>
    );
  };

  return (
    <>
      <Helmet>
        <title>{isUpload ? "Test Upload Basic Information" : "Basic Information"}</title>
      </Helmet>

      <div className="biform">
        <h1 className="page-header">{isUpload ? "Test Upload Basic Information" : "Basic Information"}</h1>
        <div className="biinstruc">
          Required fields are indicated with a{" "}
          <span className="required_label">*</span>.
          <p>
            If you need to leave this website during the test, your progress will
            automatically be saved. Incomplete tests can be accessed by selecting
            "Manage Tests" from the navigation bar.
          </p>
          {isUpload &&
            <>
              <br />
              <p>After uploading a test, you will be redirected to the scoring screen page to verify all answers are saved correctly.
              The test will not be marked as complete until you validate the responses.</p>
            </>
          }
        </div>

        <form onSubmit={onSubmit}>
          <div>
            <ComboBox
              label={<AccountNameLabel />}
              selectedKey={accountName}
              onSelectionChange={(selected) => {
                setAccountName(selected);
                let industryId = allAccounts.filter(account => account.companyname === selected)[0] && allAccounts.filter(account => account.companyname === selected)[0].industryid;
                industryId ? setIndustryId(industryId) : setIndustryId(0);
                if (allAccounts.filter(account => account.companyname === selected).length === 0) {
                  setDisableIndustryInput(false);
                } else {
                  setDisableIndustryInput(true);
                }
              }}
              onInputChange={(value) => {
                if (allAccounts.filter(account => account.companyname === value).length === 0) {
                  setDisableIndustryInput(false);
                } else {
                  setDisableIndustryInput(true);
                }
                setCustomAccountName(value);
              }}
              allowsCustomValue
            >
              {allAccounts &&
                allAccounts.map((account) => (
                  <Item key={account.companyname}>{account.companyname}</Item>
                ))}
            </ComboBox>
            <p id="addInfo1" className="additional-info bottom-margin">If an account name doesn't exist in the list, type one in to add it.</p>
          </div>

          <div>
            <ComboBox
              label={<IndustryNameLabel />}
              selectedKey={industryId}
              onSelectionChange={(selected) => setIndustryId(selected)}
              inputValue={industries.filter(industry => industryId === industry.industryid)[0] && industries.filter(industry => industryId === industry.industryid)[0].industryname}
              disabled={disableIndustryInput}
            >
              {industries &&
                industries.map((industry) => (
                  <Item key={industry.industryid}>{industry.industryname}</Item>
                ))}
            </ComboBox>
          </div>

          <label className="biform" htmlFor="projectName">
            Project Name<span className="required_label">*</span>
          </label>
          {projectNameError && (
            <div>
              <strong className="error_label" id="project-name-error">
                {projectNameErrorText}
              </strong>
            </div>
          )}
          <input
            type="text"
            id="projectName"
            name="projectName"
            required
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            aria-invalid={projectNameError}
            aria-describedby={projectNameError ? "project-name-error" : null}
          ></input>

          <label className="biform" htmlFor="projectType">
            Type of audit
          </label>
          <select 
            id="projectType"
            ref={selectMenu}
            onChange={(e) => setAuditType(e.target.value)}>
            <option value="website">Website</option>
            <option value="app">App</option>
          </select>

          { auditType === 'website' && ( 
          <div id="websiteOptions">
            <label className="biform" htmlFor="projectURL">
              Base URL (e.g - https://www.xyz.com)<span className="required_label">*</span>
            </label>
            <input
              type="url"
              id="projectURL"
              name="projectURL"
              value={projectURL}
              required
              onChange={(e) => setProjectURL(e.target.value)}
              onBlur={(e) => {try { setProjectURL(new URL(e.target.value).origin) } catch(err) {} } }
            ></input>
           
            <input
              type="checkbox"
              id="popetech"
              name="popetech"
              aria-describedby="addInfo2"
              onChange={(e) => setIsScan(e.target.checked)}
              defaultChecked={!isUpload}
            ></input>
            <label className="biform" htmlFor="popetech">
              Set-up pages for automatic scanning
            </label>
            <p id="addInfo2" className="additional-info">This will set-up the project within the Pope Tech accessibility platform and Googles Lighthouse. If this is behind a protected environment, the credentials will need to be set-up in the <a href="https://login.pope.tech/login" target="_blank">Pope Tech admin panel.</a> 
            <img
              className="icon"
              src={icon}
              alt="(opens in a new window)."
              />
              </p>
          </div>
          )}

          <div className={isUpload ? "nav-single-button" : "nav"}>
            <input
              type="button"
              className="btn"
              name="prevButton"
              onClick={() => navigate("/newTest")}
              value="Previous"
              readOnly
              hidden={isUpload}
            />
            <input type="Submit" className="btn" value="Next" readOnly />
          </div>
        </form>
      </div>
    </>
  );
};
export default BasicInfo;
