import * as React from "react";
import { RoundedDiv } from "../DashboardGraph/__styled__/DashboardGraph";
import "./results.css";
import { useLocation, useNavigate } from "react-router-dom";
import BackButtonReport from "../BackButtonReport";
import PrintButton from "../PrintPage";
import { marked } from "marked";
import { cssForPDF, cssForWord } from "../../utilities/exportCSS";
import Markdown from 'marked-react';
import { insertOpenIcon, replaceGreaterThan } from "../../functions/addOpenInNewTab";
import { useEffect } from "react";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { appRoles, protectedResources } from "../../authConfig";
import icon from "../icons/open.svg"
import { useMsal } from "@azure/msal-react";
import CircularProgress from '@mui/material/CircularProgress';

export default function Report() {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const userID = account.localAccountId;

  const [showTable, setShowTable] = React.useState(false);
  const [newData, setNewData] = React.useState();
  const [headerData, setHeaderData] = React.useState(undefined);
  const [isMyOwnTest, setIsMyOwnTest] = React.useState(false);
  const [isDownloadingPDF, setIsDownloadingPDF] = React.useState(false);
  let company = "";
  let date = "";
  let score = 0;
  let urlTested = "";
  let filteredResults = [];
  let testid = 0;
  let showBackButton = false;

  const params = new URLSearchParams(location.search);

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.getReport.scopes.read
  });

  useEffect(() => {
    let classesList = ['links-markdown', 'links-markdown:hover'];

    insertOpenIcon('guidance', classesList, false);
    replaceGreaterThan('guidance', false);
  }, [])

  if (state?.id && state?.showBackButton != null) {
    testid = state.id;
    showBackButton = state.showBackButton;
  } else if (params?.get("id") && params?.get("showBackButton")) {
    testid = params.get("id");
    showBackButton = params.get("showBackButton") === 'true';
  }

  useEffect(() => {
    if (!newData) {
      execute("GET", protectedResources.getReport.endpoint + testid, protectedResources.getReport.scopes).then((response) => {
        if (response) {
          let data = response;
          company = data[0].companyname;
          date = data[0].date.slice(0, 10)
          score = data[0].testscore
          urlTested = data[0].projecturl;

          setHeaderData({
            company: data[0].companyname,
            date: data[0].date.slice(0, 10),
            score: data[0].testscore,
            urlTested: data[0].projecturl,
          });

          if (data[0].criteria) {
            setShowTable(true);
          }

          let resultFields = [];

          for (let i = 0; i < data.length; i++) {
            if (!data[i].shouldDisplay) {
              continue;
            }

            const newReportField = {
              id: i,
              criteria: data[i].criteria,
              guidance: data[i].feedback, // the previous version of the code was returning the guidance intro on the interface twice
              notes: data[i].notes,
              capability: data[i].capability,
              criteriaid: data[i].criteriaid,
              url: data[i].url,
              score: data[i].responsescore,
            };
            resultFields.push(newReportField);
          }

          //pre-processing of json data for graph
          filteredResults = resultFields.map((result) => ({
            criteria: result.criteria,
            guidance: result.guidance,
            urlNotes: result.notes,
            urlNotes: `${result.url} \n\n ${result.notes.replaceAll("\\n", "\n")}`,
            url: result.url,
            notes: result.notes.replaceAll("\\n", "\n"),
            capability: result.capability,
            criteriaid: result.criteriaid,
            url: result.url,
            score: result.score,
          }));
          setNewData(filteredResults);
        }
      });
    }
  }, [execute, newData]);

  useEffect(() => {
    if (testid) {
      execute("GET", protectedResources.getBasicInfo.endpoint + testid, protectedResources.getBasicInfo.scopes).then(
        (response) => {
          if (response?.length > 0 && response[0].userid) {
            setIsMyOwnTest(response[0].userid === userID || account.idTokenClaims['roles'].includes(appRoles.superAdmin));
          }
        }
      );
    }
  }, [execute, testid]);

  const dateParse = (date) => {
    let parseDate = new Date(date);
    parseDate = new Date(parseDate.getTime() + parseDate.getTimezoneOffset() * 60000)
    const month = parseDate.toLocaleString('default', { month: 'short' });
    const day = parseDate.getDate();
    const year = parseDate.getFullYear();
    return (month + ". " + day + ", " + year);
  };

  const navToQuestion = (event, questionNum, url, notes, score, capability) => {
    event.preventDefault();
    navigate("/ScoringScreen", {
      state: { testId: testid, question: questionNum, url, notes, score, capability, isEditCompleted: true }
    });
  }

  const replace_symbols = (htmlData) => {
    htmlData = htmlData.replaceAll(/&lt;/g, "<");
    htmlData = htmlData.replaceAll(/&gt;/g, ">");
    htmlData = htmlData.replaceAll(/&amp;/g, "&");
    htmlData = htmlData.replaceAll(/&quot;/g, "\"");
    htmlData = htmlData.replaceAll(/&apos;/g, "'");
    return htmlData;
  }

  const generatePDF = async (element, filename, title, css, footHTML) => {
    setIsDownloadingPDF(true);

    let preHtml = `<html lang="en">
    <head>
    <title>${title}</title>
    <meta name="subject" content="Accessibility Report ${filename}"></meta>
    <meta name="description" content="Accessibility Report ${filename}"></meta>
    <meta name="author" content="AID Generated Report"></meta>
    <style>${cssForPDF.data}</style>
    </head>
    <body>`

    let postHtml = `${footHTML}</body></html>`;

    // clone the document 
    const clonedDocument = document.cloneNode(true);

    // remove url & notes column and separate them out into 2 columns
    let urlNotesColHeader = clonedDocument.getElementById("urlCol");

    let urlColHeader = clonedDocument.createElement("th");
    urlColHeader.innerHTML = "URLs Tested";
    urlColHeader.id = "urlCol";
    urlColHeader.scope = "col";
    urlNotesColHeader.parentNode.appendChild(urlColHeader);

    let urlNotesHeader = clonedDocument.createElement("th");
    urlNotesHeader.innerHTML = "Notes";
    urlNotesHeader.id = "notesCol";
    urlNotesHeader.scope = "col";
    urlNotesColHeader.parentNode.appendChild(urlNotesHeader);

    urlColHeader.parentNode.removeChild(urlNotesColHeader);

    // remove capability and add it back at end to maintain order
    let capabilityColHeader = clonedDocument.querySelector("[class=capability]");
    let capabilityCol = clonedDocument.createElement("th");
    capabilityCol.innerHTML = "Capability";
    capabilityCol.id = "capability";
    capabilityCol.scope = "col";
    capabilityColHeader.parentNode.appendChild(capabilityCol);
    capabilityColHeader.parentNode.removeChild(capabilityColHeader);

    // get all url titles
    let urlData = [];

    newData.forEach(d => {
      if (d.url.includes("http")) {
        d.url.split("http").map(link => {
          if (link.length === 0) return;
          link = "http" + link.replace(/\s+/g, '');
          let linkString = link.charAt(link.length - 1) === ";" || link.charAt(link.length - 1) === "," ? link.slice(0, -1) : link;
          urlData.push({
            url: linkString,
            criteriaId: d.criteriaid,
            title: ""
          })
        });
      }
    });

    let missingLinks = [];
    let titleData;
    
    titleData = await execute("POST", protectedResources.getTitle.endpoint, protectedResources.getTitle.scopes, { "data": urlData });
    
    // remove rows and rebuild table body
    clonedDocument.querySelectorAll("tr").forEach(row => {
      let urlNotesRow = row.querySelector(".reportCells.urlNotes");
      let capabilityRow = row.querySelector("[id=capabilityCol]");
      const criteriaId = row.getAttribute("criteriaId");

      if (!urlNotesRow || !capabilityRow || !criteriaId) return;

      let notesRow = clonedDocument.createElement("td");
      notesRow.className = "reportCells";
      let urlRow = clonedDocument.createElement("td");
      urlRow.className = "reportCells";
      urlRow.id = "urlRow";
      let capabilityRowNew = clonedDocument.createElement("td");
      capabilityRowNew.className = "reportCells";
      capabilityRowNew.id = "capabilityCol";

      let rowData = newData.filter(row => row.criteriaid === Number(criteriaId))[0];

      notesRow.innerHTML = rowData.notes.replaceAll("\\n", "\n").replaceAll("<", "&lt;").replaceAll(">", "&gt;").replaceAll("&", "&amp;").replaceAll("\"", "&quot;").replaceAll("'", "&#039;");

      if (rowData.url.includes("http")) {
        rowData.url.split("http").map(link => {
          if (link.length === 0) return;

          if (!link.includes("/")) {
            let p = clonedDocument.createElement("p");
            p.innerHTML = link.replaceAll("\\n", "\n");
            urlRow.appendChild(p);
            return;
          }

          link = "http" + link.replace(/\s+/g, '');
          let linkString = link.charAt(link.length - 1) === ";" || link.charAt(link.length - 1) === "," ? link.slice(0, -1) : link;

          let div = clonedDocument.createElement("div");
          let linkHTML = clonedDocument.createElement("a");
          linkHTML.className = "links-markdown";
          linkHTML.href = linkString;
          linkHTML.target = "_blank";

          let title = titleData && titleData.data.length > 0 && titleData.data.filter(res => res.criteriaId === Number(rowData.criteriaid)).filter(res => res.url === linkString);
          if (title.length > 0 && title[0].title !== "") {
            linkHTML.innerHTML = title[0].title;
          } else {
            let existingLink = missingLinks.filter(link => link.url === linkString);
            if (existingLink.length > 0) {
              linkHTML.innerHTML = existingLink[0].title;
            } else {
              linkHTML.innerHTML = `Page ${missingLinks.length + 1}`;
              missingLinks.push({ title: `Page ${missingLinks.length + 1}`, url: linkString });
            }
          }

          div.innerHTML = ""
          div.appendChild(linkHTML);
          urlRow.appendChild(div);
        });
      } else {
        let p = clonedDocument.createElement("p");
        p.innerHTML = rowData.url.replaceAll("\\n", "\n");
        urlRow.appendChild(p);
      }

      capabilityRowNew.innerHTML = rowData.capability;

      urlNotesRow.parentNode.appendChild(urlRow);
      urlNotesRow.parentNode.appendChild(notesRow);
      capabilityRow.parentNode.appendChild(capabilityRowNew);

      urlNotesRow.parentNode.removeChild(urlNotesRow);
      capabilityRow.parentNode.removeChild(capabilityRow);
    });

    // retrieve the cells with the edit button in them
    let editButtonList = clonedDocument.getElementsByClassName("editButton");

    if (editButtonList.length > 0) {

      // remove the cells with the edit button in them
      while (editButtonList.length > 0) {
        editButtonList[0].parentNode.removeChild(editButtonList[0]);
      }

      // remove the header with the title "actions"
      let actionsHeaderElement = clonedDocument.getElementsByClassName("edit");
      actionsHeaderElement[0].parentNode.removeChild(actionsHeaderElement[0]);
    }

    let modifyTestElement = clonedDocument.getElementsByClassName("bottom-margin");
    if (modifyTestElement && modifyTestElement.length > 0) {
      modifyTestElement[0].parentNode.removeChild(modifyTestElement[0]);
    }

    // run the markdown content in guidance cells through marked library to convert to HTML
    let guidanceData = clonedDocument.getElementsByClassName("guidance");

    for (let i = 0; i < newData.length; i++) {
      // this if-else makes code appear properly in the pdf
      if (newData[i].guidance.includes("```")) {
        let guidance = newData[i].guidance.replaceAll("<", "&lt;").replaceAll(">", "&gt;");
        guidanceData[i].innerHTML = replace_symbols(marked.parse(guidance));
      } else {
        guidanceData[i].innerHTML = replace_symbols(marked.parse(guidanceData[i].innerHTML));
      }
    }

    // need to remove any images as they are not supported (should not have any anyways but for safety)
    clonedDocument.querySelectorAll("img").forEach(img => img.remove());
    // code tags are not supported by the service so we need to remove them and repace them with p tags that have the font-family of the code fontD CFV
    clonedDocument.querySelectorAll("code").forEach(c => {
      let p = clonedDocument.createElement("p");
      p.className = "code";
      p.innerHTML = c.innerHTML;

      c.parentNode.replaceChild(p, c);
    });

    // list tags are not supported by the service so we need to remove them and repace them with div and p tags
    clonedDocument.querySelectorAll("ol").forEach(ol => {
      let div = clonedDocument.createElement("div");

      ol.querySelectorAll("li").forEach((l, index) => {
        let p = clonedDocument.createElement("p");
        p.innerHTML = `${index + 1}. ${l.innerHTML}`;
        div.appendChild(p);
      });

      ol.parentNode.replaceChild(div, ol);
    });

    clonedDocument.querySelectorAll("ul").forEach(ul => {
      let div = clonedDocument.createElement("div");

      ul.querySelectorAll("li").forEach(l => {
        let p = clonedDocument.createElement("p");
        p.innerHTML = l.innerHTML;
        div.appendChild(p);
      });

      ul.parentNode.replaceChild(div, ul);
    });

    // add link lengend
    if (missingLinks.length > 0) {
      let pageHeader = clonedDocument.getElementById("topReportInfo");
      let p = clonedDocument.createElement("p");
      p.innerHTML = "We could not get a page title for some links in this document. These links will be replaced by the text \"Page 1\", \"Page 2\", \"Page 3\", etc. Below is a legend of pages that are linked in the report."
      pageHeader.appendChild(p);
    }

    let div = clonedDocument.createElement("div");
    div.id = "linksLegendDiv";

    let pageHeader = clonedDocument.getElementById("topReportInfo");

    missingLinks.forEach(link => {
      let p = clonedDocument.createElement("p");
      let a = clonedDocument.createElement("a");
      p.innerHTML = link.title + ": ";
      a.className = "links-markdown";
      a.href = link.url;
      a.target = "_blank"; 
      a.innerHTML = link.url;
      p.appendChild(a);
      div.appendChild(p);
    });

    clonedDocument.querySelectorAll("a").forEach(a => {
      a.innerHTML.replaceAll(" ", "&nbsp;");
    });

    pageHeader.appendChild(div);

    // this is the code to convert the large table into smaller ones (one for each criteria)
    let table = clonedDocument.querySelector("table");
    table.querySelector("tbody").querySelectorAll("tr").forEach((tr, index) => {
      let criteriaTable = clonedDocument.createElement("table");
      criteriaTable.id = "rtable";
      criteriaTable.ariaLabel = `criteria-table-caption-${index}`;

      let tableHead = clonedDocument.createElement("thead");
      tableHead.id = "reportHead";
      let tableHeadRow = clonedDocument.createElement("tr");  

      let guidanceColHead = clonedDocument.createElement("th");
      guidanceColHead.innerHTML = "Guidance";
      guidanceColHead.id = "guidanceCol";
      let urlColHead = clonedDocument.createElement("th");
      urlColHead.innerHTML = "URLs Tested";
      urlColHead.id = "urlCol";
      let noteColHead = clonedDocument.createElement("th");
      noteColHead.innerHTML = "Notes";
      noteColHead.id = "noteCol";
      let capabilityColHead = clonedDocument.createElement("th");
      capabilityColHead.innerHTML = "Capability";
      capabilityColHead.id = "capabilityCol";

      tableHeadRow.appendChild(guidanceColHead);
      tableHeadRow.appendChild(urlColHead);
      tableHeadRow.appendChild(noteColHead);
      tableHeadRow.appendChild(capabilityColHead);
      tableHead.appendChild(tableHeadRow);
      criteriaTable.appendChild(tableHead);

      let row = clonedDocument.createElement("tr");
      let accessiblityIndicator = tr.querySelector(".reportCells.accessibility");
      let accessiblityIndicatorText = accessiblityIndicator.innerHTML;
      accessiblityIndicator.parentNode.removeChild(accessiblityIndicator);
      row.innerHTML = tr.innerHTML;

      criteriaTable.appendChild(row);

      let figCaption = clonedDocument.createElement("figCaption");
      figCaption.id = `criteria-table-caption-${index}`;
      let tableLabel = clonedDocument.createElement("h2");
      tableLabel.innerHTML = accessiblityIndicatorText;
      figCaption.appendChild(tableLabel);

      table.parentNode.appendChild(figCaption);
      table.parentNode.appendChild(criteriaTable);
    });

    table.parentNode.removeChild(table);

    // remove figcaption for table we removed
    let oldCaption = clonedDocument.getElementById("fig-caption");
    oldCaption.parentNode.removeChild(oldCaption);

    let innerHtml = clonedDocument.getElementById(element).innerHTML;

    let html = `${preHtml}${innerHtml}${postHtml}`;

    execute("POST", protectedResources.getPDF.endpoint, protectedResources.getPDF.scopes, { "html": html, "filename": filename }).then(
      (response) => {
        setIsDownloadingPDF(false);
        if (response && response.length > 0) {
          try {
            saveByteArray(filename, base64ToArrayBuffer(response));
          } catch (e) {
            window.alert("There was an issue creating the PDF. Please try again later or contact the AID development team if the error persists.");
          }
        } else {
          window.alert("There was an issue creating the PDF. Please try again later or contact the AID development team if the error persists.");
        }
      }
    );
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: "application/pdf" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  const generateDocument = (element, filename, title, css, footHTML) => {
    let preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>${title}</title>
    <meta name=ProgId content=Word.Document>
    <meta name=Generator content="Microsoft Word 15">
    <meta name=Originator content="Microsoft Word 15">
    <style>${css}</style>
    <xml>
      <w:WordDocument>
      <w:View>Print</w:View>
      <w:Zoom>100</w:Zoom>
      <w:DoNotOptimizeForBrowser/>
      </w:WordDocument>
    </xml>
    </head><body>`;

    let postHtml = `${footHTML}</body></html>`;

    // clone the document 
    const clonedDocument = document.cloneNode(true);

    // retrieve the cells with the edit button in them
    let editButtonList = clonedDocument.getElementsByClassName("editButton");

    if (editButtonList.length > 0) {

      // remove the cells with the edit button in them
      while (editButtonList.length > 0) {
        editButtonList[0].parentNode.removeChild(editButtonList[0]);
      }

      // remove the header with the title "actions"
      let actionsHeaderElement = clonedDocument.getElementsByClassName("edit");
      actionsHeaderElement[0].parentNode.removeChild(actionsHeaderElement[0]);
    }

    let modifyTestElement = clonedDocument.getElementsByClassName("bottom-margin");
    modifyTestElement.length > 0 && modifyTestElement[0].parentNode.removeChild(modifyTestElement[0]);

    let guidanceData = clonedDocument.getElementsByClassName("guidance");

    for (let i = 0; i < newData.length; i++) {
      // this if-else makes code appear properly in the word doc
      if (newData[i].guidance.includes("```")) {
        let guidance = newData[i].guidance.replaceAll("<", "&lt;").replaceAll(">", "&gt;");
        guidanceData[i].innerHTML = replace_symbols(marked.parse(guidance));
      } else {
        guidanceData[i].innerHTML = replace_symbols(marked.parse(guidanceData[i].innerHTML));
      }
    }

    // remove the open in new tab icon from the word doc 
    for (let i = 0; i < guidanceData.length; i++) {
      let listOfImgs = guidanceData[i].querySelectorAll("img");
      listOfImgs.forEach(element => {
        element.remove()
      });
    }

    let urlNotesData = clonedDocument.getElementsByClassName("urlNotes");
    for (let i = 0; i < urlNotesData.length; i++) {
      urlNotesData[i].innerHTML = replace_symbols(marked.parse(urlNotesData[i].innerHTML));
    }

    for (let i = 0; i < urlNotesData.length; i++) {
      let listOfImgs = urlNotesData[i].querySelectorAll("img");
      listOfImgs.forEach(element => {
        element.remove()
      });
    }

    // add in the parsed table to the cloned document
    let innerHtml = clonedDocument.getElementById(element).innerHTML;

    let html = `${preHtml}${innerHtml}${postHtml}`;

    let blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
    });

    let url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    // Create download link element
    let downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename); // IE10-11
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }

  return (
    <>
      {showBackButton && headerData && headerData.company &&
        <BackButtonReport companyName={headerData.company} />
      }
      <div id="rcontainer">
        {headerData && (
          <div id="topReportInfo">
            <h1 className="page-header">{`${headerData.company} Accessibility Report`}</h1>
            <p id="scoreh2">{`Score: ${(params.score || headerData.score).toFixed(0)}%`}</p>
            <p id="dateLbl">{`Date: ${dateParse(headerData.date).replaceAll(".", "")}`}</p>
            <p>{`Domains Tested: ${headerData.urlTested}`}</p>
          </div>
        )}
        <figcaption id="fig-caption">
          <h2 id="scoreh2v2">Response Feedback</h2>
        </figcaption>
        {isMyOwnTest && (<div className="bottom-margin">
          <a
            href={`/ScoringScreen?testId=${testid}`}
            className="link-to-response"
          >
            Modify this test
          </a>
        </div>)}
        {showTable &&
          <RoundedDiv className="print-border">
            <div id="table">
              <div className="Section1">
                <table id="rtable" aria-labelledby="fig-caption">
                  <thead id="reportHead">
                    <tr>
                      <th scope="col" id="accessibilityCol">
                        Accessibility Indicator
                      </th>
                      <th scope="col" id="guidanceCol">
                        Guidance
                      </th>
                      <th scope="col" id="urlCol">
                        URL Tested &#38; Notes
                      </th>
                      <th scope="col" className="capability">
                        Capability
                      </th>
                      {!showBackButton && isMyOwnTest &&
                        <th scope="col" className="edit">
                          Actions
                        </th>
                      }
                    </tr>
                  </thead>
                  <tbody id="reportBody">
                    {newData.length > 0 && newData.map((data, idx) => (
                      <tr key={idx} criteriaId={data.criteriaid}>
                        <td scope="row" className="reportCells accessibility"><b>{data.criteria}</b></td>
                        <td className={`reportCells guidance`} ><Markdown>{data.guidance}</Markdown></td>
                        <td className={`reportCells urlNotes`}>
                          {data.url.includes("http") && data.url.split("http").map((link) => {
                            if (link.length === 0) return;
                            if (!link.includes("/")) {
                              return link !== "" &&
                                <div>
                                  <p>{link.replaceAll("\\n", "\n")}</p>
                                </div>
                            }
                            link = "http" + link.replace(/\s+/g, '');
                            let linkString = link.charAt(link.length - 1) === ";" || link.charAt(link.length - 1) === "," ?
                              link.slice(0, -1) : link;
                            return link !== "" &&
                              <div>
                                <a className="links-markdown" href={linkString} target="_blank">{linkString}</a>
                                <img src={icon} alt="(opens link in new tab)" className="open-new-tab" />
                              </div>
                          })}
                          {!data.url.includes("http") &&
                            data.url.replaceAll("\\n", "\n")
                          }
                          <>{`\n\n`}</>
                          {data.notes.replaceAll("\\n", "\n")}
                        </td>
                        <td className="reportCells" id="capabilityCol">{data.capability}</td>
                        {!showBackButton && isMyOwnTest &&
                          <td className="editButton">
                            <a href="#" className="link-to-response" onClick={(event) => navToQuestion(event, data.criteriaid, data.url, data.urlNotes, data.score, data.capability)}> Edit Response</a>
                          </td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </RoundedDiv>
        }
        {!showTable &&
          <h2>There is no feedback to display for this account.</h2>
        }
      </div>
      <div id="actionButtons">
        {headerData && headerData.company &&
          <PrintButton name="Print Page" documentName={`${headerData.company} Accessibility Test for ${headerData.company} Performed on ${headerData.date}`} />
        }
        {headerData && headerData.company &&
          <PrintButton name="Generate Word Document" function={() => generateDocument("rcontainer", headerData.company + '-' + headerData.date + '.doc', 'Accessibility Report', cssForWord.data, new Date().toLocaleString())} documentName={`${headerData.company} Accessibility Test for ${headerData.company} Performed on ${headerData.date}`} />
        }
        {headerData && headerData.company &&
          <PrintButton name="Generate Accessible PDF" function={() => generatePDF("rcontainer", `Accessibility Report for ${headerData.company}, Performed on ${headerData.date}.pdf`, `Accessibility Report for ${headerData.company}, Performed on ${headerData.date}.pdf`, cssForPDF.data, new Date().toLocaleString())} documentName={`${headerData.company} Accessibility Test for ${headerData.company} Performed on ${headerData.date}`} />
        }
      </div>
      { isDownloadingPDF &&
        <div id="pdfPopup">
          <p>Downloading PDF</p>
          <CircularProgress />
        </div>
      }
    </>
  )
}