export const cssForWord = {

  data: `
      body {
        font-family: Helvetica, sans-serif;
        font-size: 12px;
      }
    
      #rtable {
        border-collapse: collapse;
      }

      #reportHead {
        color: white;
        background-color: black;
        font-family: Helvetica, sans-serif;
        font-size: 12px;
      }
      
      .reportCells {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 12px;
        padding-right: 12px;
        border-bottom: solid 2px #000;
        white-space: pre-line;
      }
    
      #accessibilityCol {
        text-align: center;
        padding-left: 0.5em;
      }
    
      #rtable table tbody td:last-child,
      #rtable table tbody th:last-child {
        border-right: none;
        border-radius: 0 25px 0 0;
      }
      
       
      #capabilityCol {
        text-align: center;
      }

      #urlCol {
        width: 20%;
      }      

      @page {
          size: 11in 8in;
          mso-page-orientation: landscape;
          border: solid 1px red;
          font-size: 12px;
        }
      }
      
      @page Section1 {
      }
      
      div.Section1 {
        page: Section1;
      }`
}

export const cssForPDF = {

  data: `
    body {
      font-family: 'BodyFont';
      font-size: 12px;
    }

    th {
      text-align: center;
      word-wrap: break-word;
      max-width: 200px;
    }

    thead {
      display: table-header-group;
    }

    td {
      max-width: 200px;
    }

    #urlRow {
      word-wrap: break-word;
      vertical-align: text-top;
    }
    

    #rtable {
      border-collapse: collapse;
      width: 100%;
    }

    table {
      page-break-after: always;
    }

    table:last-child {
      page-break-after: avoid;
  }

    #reportHead {
      color: white;
      background-color: black;
      font-family: 'BodyFont';
      font-size: 12px;
    }
    
    .reportCells {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 12px;
      padding-right: 12px;
      border-bottom: solid 2px #000;
      white-space: pre-line;
      vertical-align: text-top;
    }

    #linksLegendDiv {
      page-break-after: always;
    }
  
    #accessibilityCol {
      text-align: center;
      padding-left: 0.5em;
      width: 12%;
    }

    #guidanceCol {
      text-align: center;
      padding-left: 0.5em;
      width: 38%;
    }

    #urlCol {
      width: 12%;
    }
  
    #rtable table tbody td:last-child,
    #rtable table tbody th:last-child {
      border-right: none;
      border-radius: 0 25px 0 0;
    }
    
     
    #capabilityCol {
      text-align: center;
    }


    .code {
      font-family: 'CodeFont';
      font-size: 12px;
      display: inline;
    }

    @page {
        size: 11in 8in;
        mso-page-orientation: landscape;
        font-size: 12px;
        thead {
            display: table-header-group;
        }
      }
    }
    `
}