import React, { useEffect } from "react";
import "./intro-screen.css";
import "../Scoring_Screen/scoringscreen.css";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { loginRequest, protectedResources } from "../../authConfig";

const IntroScreen = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const { error, execute } = useFetchWithMsal({
    scopes: [
      ...protectedResources.getTestVersions.scopes.read,
      ...protectedResources.getDashboard.scopes.read,
      ...protectedResources.addUser.scopes.write,
    ],
  });

  const getData = async () => {
    execute("GET", protectedResources.getTestVersions.endpoint, protectedResources.getTestVersions.scopes).then(
      (response) => {
        return response;
      }
    );
    execute("GET", protectedResources.getDashboard.endpoint, protectedResources.getDashboard.scopes).then(
      (response) => {
        return response;
      }
    );
  };

  useEffect(() => {
    getData().then((response) => {});
    const body = {
      userId: account.localAccountId
    };
    execute("POST", protectedResources.addUser.endpoint, protectedResources.addUser.scopes, body)
  }, [execute]);

  return (
    <>
      <AuthenticatedTemplate
        username={account.username}
        localAccountId={account.localAccountId}
        homeAccountId={account.homeAccountId}
      >
        <Helmet>
          <title>Home</title>
        </Helmet>
        <div id="intro-container">
          <div id="intro-content-container">
            <h1
              id="intro-screen-title"
              className="page-header"
            >{`Welcome, ${account?.name}!`}</h1>
            <div id="intro-main-text">
              {" "}
              <p>
                The PS Accessibility Indicators Data scoring tool uses a series
                of manual and automated tests to check digital experiences for
                the presence of key accessibility features and the lack of key
                accessibility barriers. The test typically takes at least 1 hour
                to complete.{" "}
                <a
                  className="ntslink"
                  id="mainContent"
                  href="/newTest"
                  onClick={() => navigate("/newTest")}
                >
                  Select "New Test" to begin.
                </a>
              </p>
              <div id="creds">
                <details>
                  <summary
                    className="collapse"
                    title="Select to read about the website!"
                  >
                    Website Credits
                  </summary>
                  <p className="collapse_info">
                    The original proof-of-concept version of this web 
                    application was created by interns in the summer 
                    of 2022 listed below. The final production version 
                    was completed by Zoltan Hawryluk, Samantha Cattani, 
                    Chloe Pappas, Manjoy Malhotra, Gabby Alcantara, and Noel Tibbles.
                    <br></br>
                    <br></br>
                    The goal of developing this tool was to have a functional
                    and accessible website for users to test the accessibility
                    of their client’s sites. Originally the accessibility test
                    was an excel file which was then used as the information for
                    the questions on the website.
                    <br></br>
                    <br></br>
                    The site was created by the following interns:
                    <br></br>
                    <br></br> <b>Front-End (Houston):</b> Katie Salvaggio (PM),
                    Justice Chang (SWE), Cesar Garcia (SWE), Ziyan Maknojia
                    (SWE), Gamaliel Medrano (SWE), Courtney Nguyen (SWE), Nghia
                    T. Nguyen (SWE), Lexcee Grant (Student Worker)
                    <br></br>
                    <br></br> <b>Admin & Dashboard (Toronto):</b> Nujaimah Ahmed
                    (PM), Gonzalo Soto Mallqui (PM), Victoria Phillips (PM),
                    Barrett Arbour (SWE), Foram Gandhi (SWE), Devynn Garrow
                    (SWE), Cierra Leitman (SWE), Yu Yun Liu (SWE), Celine Tsang
                    (Student Worker)
                    <br></br>
                    <br></br> <b>Database (Boston):</b> Gary Qin (PM, Scrum
                    Master), Lindsay Groves (PM), Brianna Anderson (PM), Jackie
                    Hong (SWE), Aditya Khanna (SWE), Heidi Huang (SWE), Wyatt
                    Morris (SWE), Lauren Smith (SWE), Annie Chen (Student
                    Worker)
                  </p>
                </details>
              </div>
            </div>
          </div>
        </div>
      </AuthenticatedTemplate>
    </>
  );
};

export default IntroScreen;
